import React, { useState } from 'react';
import * as el from './GlobalHeader.styled';
import { injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';
import locales from '../../constants/locales';
import LocalizedLink from '../LocalizedLink/LocalizedLink';

function GlobalNavigationLinks(locale) {
    console.log('locale: ', locale);
  const careerLink = 'https://careers.intact.ca/ca/' + locale.locale + '/c/intact-lab-jobs';
  // const teamLink = 'https://intact.phenompro.com/ca/' + locale.locale + '/intactlab';
  return (
    <>
      <el.ExternalNavLink to="articles" href={careerLink} target="_blank">
        <FormattedHTMLMessage id="GlobalNavigationLinks.jobs" />
      </el.ExternalNavLink>

      <el.ExternalNavLink to="articles" href="https://medium.com/intactlab" target="_blank">
        <FormattedHTMLMessage id="GlobalNavigationLinks.articles" />
      </el.ExternalNavLink>

      {/*<el.ExternalNavLink to="/events/" href="https://www.facebook.com/pg/IntactLab/events/?ref=page_internal" target="_blank">*/}
      {/*  <FormattedHTMLMessage id="GlobalNavigationLinks.events" />*/}
      {/*</el.ExternalNavLink>*/}

      {/*<el.ExternalNavLink to="/team/" href={teamLink} target="_blank">*/}
      {/*  <FormattedHTMLMessage id="GlobalNavigationLinks.team" />*/}
      {/*</el.ExternalNavLink>*/}
    </>
  );
}

function LanguageControls(props) {
    // Condition needed (if using browser window or document type) for gatsby build to work on server side
    if (typeof window !== 'undefined') {
        const getUrl = (currentLocale, switchToLocale) => {
          const currentUrlPath = window.location.pathname;
          if (currentLocale === switchToLocale) {
            return currentUrlPath;
          }

          if (currentLocale === 'en' && switchToLocale === 'fr') {
            return `fr/${currentUrlPath}`;
          } else if (currentLocale === 'fr' && switchToLocale === 'en') {
            return currentUrlPath.replace('fr/', '');
          }
        };

        return (
          <el.LanguageContainer>
            <el.LanguageButton to={getUrl(props.locale, 'en')} className={props.locale === 'en' ? "active" : ""}>
              EN
            </el.LanguageButton>
            <el.SeperatorSpan>|</el.SeperatorSpan>
            <el.LanguageButton to={getUrl(props.locale, 'fr')} className={props.locale === 'fr' ? "active" : ""}>
              FR
            </el.LanguageButton>
          </el.LanguageContainer>
        );
    }
    // return something for LanguageControls so that gatsby can compile/build when type window or document is not available
    return (
        <el.LanguageContainer></el.LanguageContainer>
    );
}

//THIS VERSION DOES BUILD, but CANNOT switch back and forth between languages, has errors in console, and is a little terse

// function LanguageControls(props) {
//   return (
//     <el.LanguageContainer>
//       {Object.keys(props.locales).map((localeObj, index) =>
//         props.locales[localeObj].locale === 'EN' ? (
//           <>
//             <el.LanguageButton
//               localeObj={localeObj}
//               key={localeObj}
//               locales={props.locales}
//               locale={props.locale}
//             />{' '}
//             <el.SeperatorSpan>|</el.SeperatorSpan>
//           </>
//         ) : (
//           <el.LanguageButton
//             localeObj={localeObj}
//             key={localeObj}
//             locales={props.locales}
//             locale={props.locale}
//           />
//         )
//       )}
//     </el.LanguageContainer>
//   );
// }

const GlobalHeader = ({ intl: { locale } }) => {
  const [isMobileMenuOpen, setMenuState] = useState(false);
  return (
    <>
      <el.HeaderContainer>
        <el.LogoLinkContainer mobileMenuOpen={isMobileMenuOpen}>
          <LocalizedLink to="/" />
        </el.LogoLinkContainer>
        <el.NavigationMenu>
          <el.DesktopNavigationContainer>
            <GlobalNavigationLinks locale={locale} />
            <LanguageControls locales={locales} locale={locale} />
          </el.DesktopNavigationContainer>
          <el.MobileMenuButton
            aria-label="Open mobile menu"
            onClick={() => setMenuState(!isMobileMenuOpen)}
            mobileMenuOpen={isMobileMenuOpen}
          />
        </el.NavigationMenu>
      </el.HeaderContainer>
      <el.MobileNavigationContainer
        aria-hidden={!isMobileMenuOpen}
        mobileMenuOpen={isMobileMenuOpen}
      >
        <el.MobileLinksContainer>
          <GlobalNavigationLinks locale={locale} />
        </el.MobileLinksContainer>
        <LanguageControls locales={locales} locale={locale} />
      </el.MobileNavigationContainer>
    </>
  );
};

GlobalHeader.propTypes = { intl: intlShape.isRequired };

export default injectIntl(GlobalHeader);
