import React from 'react';
import styled, { css } from 'styled-components';
import LocalizedLink from '../LocalizedLink/LocalizedLink';
import { Link } from 'gatsby';
import * as vars from '../../constants/css';
import whiteBulb from '../../images/bulb_white.svg';
import hamburgerMenu from '../../images/menu-hamburger.svg';
import xClose from '../../images/x-close.svg';

//console.log('props'. props);

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  z-index: 100;
  @media (min-width: ${vars.breakpoints.md}) {
    background: rgba(255, 255, 255, 1);
    height: 100px;
  }
`;


export const LogoLinkContainer = styled.div`
  width: 50px;
  background: ${vars.branding.primary} url(${whiteBulb}) no-repeat center center;
  background-size: 60%;

  @media (min-width: ${vars.breakpoints.md}) {
    width: ${vars.asideGutterWidth};
    height: ${vars.asideGutterWidth};
    background: ${vars.branding.primary} url(${whiteBulb}) no-repeat center
      center;
  }

  a {
    /* Can't style Gatsby link directly, so apply through parent and child */
    width: 100%;
    height: 100%;
    display: block;
  }
`;

export const NavigationMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const DesktopNavigationContainer = styled.nav`
  height: 100%;
  display: none;
  @media (min-width: ${vars.breakpoints.md}) {
    display: flex;
    align-items: center;
  }
`;

const headerLinks = css`
  text-align: center !important;
  line-height: 1.25;
  text-decoration: none;
  margin: 20px 0 0 0;
  color: #fff;
  font-size: 1.5rem;
  span {
    padding: 0px 0;
  }
  @media (min-width: ${vars.breakpoints.md}) {
    span {
      display: block;
    }
  }

  @media (min-width: ${vars.breakpoints.md}) {
    font-size: 1rem;
    line-height: 1;
    margin: 0 20px 0 0;
    color: ${vars.branding.black};
    &:hover {
      span span {
        background: ${vars.branding.paleGreen};
        font-weight: 700;
      }
    }
  }
`

export const GlobalNavLink = styled(props => <LocalizedLink {...props} />)`
  ${headerLinks}
`;

export const ExternalNavLink = styled.a`
  ${headerLinks}
`;

export const HighlightBlock = styled.span`
  display: inline-block;
  font-weight: 700;
  background: transparent;
  transition: all 200ms ease-out;
`;

export const LanguageContainer = styled.div`
  width: 100%;
  color: #fff;
  background: ${vars.branding.secondaryDark};
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 20px #333;
  a {
    text-align: center;
  }
  @media (min-width: ${vars.breakpoints.md}) {
    padding: 10px;
    height: 100%;
    width: ${vars.asideGutterWidth};
    box-shadow: none;
    justify-content: center;
  }
`;

export const SeperatorSpan = styled.span`
  display: none;
  @media (min-width: ${vars.breakpoints.md}) {
    display: inline;
    margin: 0 5px;
  }
`;

export const LanguageButton = styled(props => <Link {...props} />)`
  background: ${p =>
    p.active ? vars.branding.primaryLight : vars.branding.secondaryDark};
  border: none;
  width: 50%;
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;
  font-weight: 700;
  color: #fff;
  font-size: 1.25rem;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
  
  &.active {
    background: ${vars.branding.primaryLight};
  }

  @media (min-width: ${vars.breakpoints.md}) {
    width: auto;
    padding: 0;
    background: transparent;
    display: inline;
    font-size: 1rem;
    font-weight: 400;
    color: ${p => (p.active ? vars.branding.primaryLight : '#fff')};
    &:hover {
      color: ${vars.branding.primaryLight};
    }
    &.active {
      background: none;
  }
  }
`;

// ******************** MOBILE ONLY ELEMENTS ********************

export const MobileLinksContainer = styled.nav`
  height: 100%;
  width: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  a { 
    text-align: left;
  }
`;

export const MobileMenuButton = styled.button`
  border: none;
  width: 50px;
  height: 100%;
  background: ${vars.branding.secondaryDark}
    url(${p => (p.mobileMenuOpen ? xClose : hamburgerMenu)}) no-repeat center
    center;
  &:hover {
    cursor: pointer;
  }
  z-index: 100;

  @media (min-width: ${vars.breakpoints.md}) {
    width: ${vars.asideGutterWidth};
    height: ${vars.asideGutterWidth};
    display: none;
  }
`;

export const MobileNavigationContainer = styled.nav`
  transition: height 200ms ease-out;
  position: fixed;
  top: 0px;
  height: 0px;
  width: 0px;
  background: ${vars.branding.secondaryDark};
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  z-index: 20;

  ${p =>
    p.mobileMenuOpen &&
    css`
      height: 100%;
      width: 100%;
    `};
  @media (min-width: ${vars.breakpoints.md}) {
    display: none;
  }
`;
