import styled from 'styled-components';
import * as vars from '../../constants/css';

export const ButtonGroup = styled.div`
  display: none;
  height: 50px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  transform-origin: left bottom;
  transition: all 250ms ease-out;
  transform: rotate(-90deg)
    ${p => (p.showButton ? 'translateY(100%)' : 'translateY(0)')};

  @media (min-width: ${vars.breakpoints.md}) {
    display: block;
    height: 100px;
  }
`;
